import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { defaultAxiosConfig } from 'app/constants'
import Cinder from './Cinder'
import Glance from './Glance'
import Keystone from './Keystone'
import Murano from './Murano'
import Neutron from './Neutron'
import Nova from './Nova'
import Qbert from './Qbert'
import ResMgr from './ResMgr'
import Clemency from 'api-client/Clemency'
import Helm from './Helm'
import Hagrid from './Hagrid'
import Sunpike from './Sunpike'

import { normalizeResponse } from 'api-client/helpers'
import { hasPathStr, pathStr } from 'utils/fp'
import { prop, pick, has, cond, T, identity, when, mergeDeepLeft } from 'ramda'
import { isPlainObject, pathJoin } from 'utils/misc'
import {
  DDUHealth,
  IRawRequestGetParams,
  IRawRequestPostParams,
  IBasicRequestGetParams,
  IBasicRequestPostParams,
  IBasicRequestDeleteParams,
} from './model'
import ApiService from 'api-client/ApiService'
import Bugsnag from 'utils/bugsnag'
import { someAsync } from 'utils/async'
import PreferenceStore from './PreferenceStore'
import config from 'app-config'
import store from 'app/store'

const addApiRequestMetadata = (apiClass, requestMethod) => {
  return { apiClassMetadata: { apiClass, requestMethod } }
}
const requestKeysToTrack = [
  'readyState',
  'response',
  'responseText',
  'responseType',
  'responseURL',
  'responseXML',
  'status',
  'statusText',
  'timeout',
  'withCredentials',
]
const formatResponseRequestPayload = (data = {}) => pick(requestKeysToTrack, data)

const formatResponseConfigPayload = (cfg: AxiosRequestConfig = {}) => ({
  url: cfg?.url,
  method: cfg?.method,
  data: typeof cfg?.data === 'string' ? JSON.parse(cfg?.data) : cfg?.data,
  headers: cfg?.headers,
})

interface ApiClientOptions {
  [key: string]: any

  keystoneEndpoint: string
}

export const apiClassesByApiName = {
  cinder: Cinder,
  glance: Glance,
  keystone: Keystone,
  neutron: Neutron,
  nova: Nova,
  murano: Murano,
  resMgr: ResMgr,
  qbert: Qbert,
  clemency: Clemency,
  helm: Helm,
  hagrid: Hagrid,
  preferenceStore: PreferenceStore,
  sunpike: Sunpike,
}

class ApiClient {
  public options: ApiClientOptions

  public cinder: Cinder
  public glance: Glance
  public keystone: Keystone
  public neutron: Neutron
  public nova: Nova
  public murano: Murano
  public resMgr: ResMgr
  public qbert: Qbert
  public clemency: Clemency
  public helm: Helm
  public hagrid: Hagrid
  public preferenceStore: PreferenceStore
  public sunpike: Sunpike
  public catalog = {}
  unscopedToken = null
  scopedToken = null
  activeProjectId = null
  serviceCatalog = null
  endpoints = null

  private readonly axiosInstance: AxiosInstance
  private static instance: ApiClient

  static init(options: ApiClientOptions) {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(options)
    }
    return ApiClient.instance
  }

  static getInstance() {
    if (!ApiClient.instance) {
      console.warn(
        'ApiClient instance has not been initialized, please call ApiClient.init to instantiate it',
      )
    }
    return ApiClient.instance || ({} as ApiClient)
  }

  static hydrate(state) {
    const options = {
      keystoneEndpoint: state.keystoneEndpoint,
    }
    const client = new ApiClient(options)
    client.catalog = state.catalog
    return client
  }

  static async refreshApiEndpoints(instance = ApiClient.getInstance()) {
    await someAsync([
      instance.keystone.initialize(),
      instance.cinder.initialize(),
      instance.glance.initialize(),
      instance.neutron.initialize(),
      instance.nova.initialize(),
      instance.murano.initialize(),
      instance.resMgr.initialize(),
      instance.qbert.initialize(),
      instance.clemency.initialize(),
      instance.helm.initialize(),
      instance.hagrid.initialize(),
      instance.preferenceStore.initialize(),
      instance.sunpike.initialize(),
    ])
  }

  // @deprecated use the store directly
  get activeRegion(): string {
    const {
      session: { activeRegion },
    } = store.getState()
    return activeRegion
  }

  apiServices = {}

  constructor(options: ApiClientOptions) {
    this.options = options
    if (!options.keystoneEndpoint) {
      console.warn('keystoneEndpoint required')
    }
    const getResponseError: any = (cond as any)([
      [hasPathStr('response.data.error'), pathStr('response.data.error')],
      [hasPathStr('response.data.message'), pathStr('response.data.message')],
      [has('error'), when<any, string>(isPlainObject, prop('error'))],
      [T, identity],
    ])
    const getErrorText = (err) => {
      if (err.message) {
        return err.message
      }
      if (typeof err === 'object') {
        return JSON.stringify(err)
      }
      return err.toString()
    }

    this.axiosInstance = axios.create({ ...defaultAxiosConfig, ...(options.axios || {}) })
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const response = error?.response

        const responseError = getResponseError(error)
        const errorMessage = getErrorText(responseError)

        const errStack = error?.stack || error?.stackTrace
        const responseConfig = response?.config
        const apiClassMetadata = responseConfig?.apiClassMetadata || {}

        const responseData = response?.data
        const responseStatus = response?.status
        const responseStatusText = response?.statusText

        const { apiClass, requestMethod } = apiClassMetadata
        const metaData = apiClassesByApiName?.[apiClass]?.apiMethodsMetadata?.find(
          (metadata) => metadata.name === requestMethod,
        )
        // we have to define this here because its used inside the toolbar,
        // and not all selectors are defined when that file is imported
        // (reselct exception thrown if we import the notification selector)

        const isClusterError = !!metaData?.error?.isClusterError

        const errResponseJson = {
          config: formatResponseConfigPayload(responseConfig),
          data: responseData,
          status: responseStatus,
          statusText: responseStatusText,
        }

        // If data contains user password, remove it
        if (responseConfig?.data?.includes('{"methods":["password"]')) {
          const dataObj = JSON.parse(responseConfig?.data)
          if (dataObj?.auth?.identity?.password?.user?.password) {
            dataObj.auth.identity.password.user.password = 'SENSITIVE_DATA_REMOVED'
            responseConfig.data = JSON.stringify(dataObj)
          }
        }

        Bugsnag.notify(new Error(errorMessage), (bugsnagEvent: any) => {
          /*
            update the context of the error to be the same as
            the error message, this way they can be uniquely split apart
          */
          bugsnagEvent.context = errorMessage
          bugsnagEvent.groupingHash = errorMessage

          bugsnagEvent.addMetadata('Response Config', responseConfig)
          bugsnagEvent.addMetadata('Request', formatResponseRequestPayload(response?.request))
          bugsnagEvent.addMetadata('Response Data', responseData)
          bugsnagEvent.addMetadata('Stacktrace', errStack)
        })

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          isClusterError,
          apiClassMetadata,
          apiErrorMetadata: metaData?.error || {},
          response: errResponseJson,
          stack: errStack,
          err: responseError,
        })
      },
    )

    // Keystone is used by all the other services so it must be initialized first
    this.keystone = this.addApiService(new Keystone(this))
    this.cinder = this.addApiService(new Cinder(this))
    this.glance = this.addApiService(new Glance(this))
    this.neutron = this.addApiService(new Neutron(this))
    this.nova = this.addApiService(new Nova(this))
    this.murano = this.addApiService(new Murano(this))
    this.resMgr = this.addApiService(new ResMgr(this))
    this.qbert = this.addApiService(new Qbert(this))
    this.clemency = this.addApiService(new Clemency(this))
    this.helm = this.addApiService(new Helm(this))
    this.hagrid = this.addApiService(new Hagrid(this))
    this.preferenceStore = this.addApiService(new PreferenceStore(this))
    this.sunpike = this.addApiService(new Sunpike(this))
  }

  addApiService = <T extends ApiService>(apiClientInstance: T) => {
    this.apiServices[apiClientInstance.getClassName()] = apiClientInstance
    return apiClientInstance
  }

  serialize = () => {
    return {
      keystoneEndpoint: this.options.keystoneEndpoint,
      unscopedToken: this.unscopedToken,
      scopedToken: this.scopedToken,
      catalog: this.catalog,
      activeProjectId: this.activeProjectId,
    }
  }

  getSystemHealth = async () => {
    const data = await this.basicGet<DDUHealth>({
      url: '/regionstatus',
      endpoint: config.apiHost,
      options: {
        clsName: 'ApiClient',
        mthdName: 'getSystemHealth',
      },
    })
    return data
  }

  getAuthHeaders = (scoped = true) => {
    const token = scoped ? this.scopedToken : this.unscopedToken
    if (!token) {
      console.warn('Auth token not initialized')
      return {}
    }
    // It's not necessary to send both headers but it's easier since we don't
    // need to pass around the url and have conditional logic.
    // Both APIs will ignore any headers they don't understand.
    const headers = {
      Authorization: `Bearer ${token}`, // required for k8s proxy api
      'X-Auth-Token': token, // required for OpenStack
    }
    return { headers }
  }

  async getEndpoint({ version, endpoint, clsName }) {
    if (endpoint === undefined) {
      endpoint = await this.apiServices[clsName].getApiEndpoint()
    }
    if (version !== undefined) {
      endpoint = endpoint.replace(/\/v3$/, `/${version}`).replace(/\/v3\//, `/${version}/`)
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.apiServices[clsName]?.scopedEnpointPath) {
      return `${endpoint}/${this.apiServices[clsName].scopedEnpointPath()}`
    }
    return endpoint
  }

  rawGet = async <T>({
    url,
    version,
    endpoint = undefined,
    config = {},
    options: { clsName, mthdName },
  }: IRawRequestGetParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const axiosConfig = {
      ...config,
      ...addApiRequestMetadata(clsName, mthdName),
    }
    const response = await this.axiosInstance.get<T>(pathJoin(endpoint, url), axiosConfig)
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return response
  }

  rawPost = async <T>({
    url,
    version,
    data,
    endpoint = undefined,
    config = {},
    options: { clsName, mthdName },
  }: IRawRequestPostParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const axiosConfig = {
      ...config,
      ...addApiRequestMetadata(clsName, mthdName),
    }
    const response = await this.axiosInstance.post<T>(pathJoin(endpoint, url), data, axiosConfig)
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return response
  }

  rawPut = async <T>({
    url,
    version,
    data,
    endpoint = undefined,
    config = {},
    options: { clsName, mthdName },
  }: IRawRequestPostParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const axiosConfig = {
      ...config,
      ...addApiRequestMetadata(clsName, mthdName),
    }
    const response = await this.axiosInstance.put<T>(pathJoin(endpoint, url), data, axiosConfig)
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return response
  }

  rawPatch = async <T>({
    url,
    version,
    data,
    endpoint = undefined,
    config = {},
    options: { clsName, mthdName },
  }: IRawRequestPostParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const axiosConfig = {
      ...config,
      ...addApiRequestMetadata(clsName, mthdName),
    }
    const response = await this.axiosInstance.patch<T>(pathJoin(endpoint, url), data, axiosConfig)
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return response
  }

  rawDelete = async <T>({
    url,
    version,
    endpoint = undefined,
    config = {},
    options: { clsName, mthdName },
  }: IRawRequestGetParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const axiosConfig = {
      ...config,
      ...addApiRequestMetadata(clsName, mthdName),
    }
    const response = await this.axiosInstance.delete<T>(pathJoin(endpoint, url), axiosConfig)
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return response
  }

  basicGet = async <T>({
    url,
    version,
    endpoint = undefined,
    params = undefined,
    options: { clsName, mthdName, extractNestedData = true },
  }: IBasicRequestGetParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const response = await this.axiosInstance.get<T>(pathJoin(endpoint, url), {
      params,
      ...this.getAuthHeaders(),
      ...addApiRequestMetadata(clsName, mthdName),
    })
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return normalizeResponse<T>(response, extractNestedData)
  }

  basicPost = async <T>({
    url,
    version,
    endpoint = undefined,
    body = undefined,
    options: { clsName, mthdName },
  }: IBasicRequestPostParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const response = await this.axiosInstance.post<T>(pathJoin(endpoint, url), body, {
      ...this.getAuthHeaders(),
      ...addApiRequestMetadata(clsName, mthdName),
    })
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return normalizeResponse<T>(response)
  }

  basicPatch = async <T>({
    url,
    version,
    endpoint = undefined,
    body = undefined,
    options: { clsName, mthdName, config = {} },
  }: IBasicRequestPostParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const response = await this.axiosInstance.patch<T>(
      pathJoin(endpoint, url),
      body,
      mergeDeepLeft(this.getAuthHeaders(), {
        ...config,
        ...addApiRequestMetadata(clsName, mthdName),
      }),
    )
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return normalizeResponse<T>(response)
  }

  basicPut = async <T>({
    url,
    version,
    endpoint = undefined,
    body = undefined,
    options: { clsName, mthdName },
  }: IBasicRequestPostParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const response = await this.axiosInstance.put<T>(pathJoin(endpoint, url), body, {
      ...this.getAuthHeaders(),
      ...addApiRequestMetadata(clsName, mthdName),
    })
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return normalizeResponse<T>(response)
  }

  basicDelete = async <T>({
    url,
    version,
    endpoint = undefined,
    options: { clsName, mthdName },
    data = undefined,
  }: IBasicRequestDeleteParams) => {
    endpoint = await this.getEndpoint({ endpoint, version, clsName })
    const response = await this.axiosInstance.delete<T>(pathJoin(endpoint, url), {
      ...this.getAuthHeaders(),
      data,
      ...addApiRequestMetadata(clsName, mthdName),
    })
    // ApiCache.instance.cacheItem(clsName, mthdName, response.data)
    return normalizeResponse<T>(response)
  }
}

export default ApiClient
