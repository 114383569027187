import Plugin from 'core/plugins/plugin'
import { argoCDLogo } from './logo'
import { AppPlugins } from 'app/constants'
import InformationalLandingPage from './components/InformationalLandingPage'

const argoCDPlugin = new Plugin(AppPlugins.ArgoCD, 'Argo CD', '/ui/argo-cd', argoCDLogo)
argoCDPlugin.setOption('singlePane', true)

argoCDPlugin.registerRoutes([
  {
    name: 'Overview',
    link: { path: '/overview', exact: true, default: true },
    component: InformationalLandingPage,
  },
])

const navItems = [
  {
    name: 'Overview',
    link: { path: '/overview' },
    icon: 'tachometer',
  },
]

argoCDPlugin.registerNavItems(navItems)

export default argoCDPlugin
