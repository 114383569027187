import { AxiosRequestConfig } from 'axios'

export enum ApiServices {
  cinder = 'Cinder',
  clemency = 'Clemency',
  glance = 'Glance',
  keystone = 'Keystone',
  murano = 'Murano',
  neturon = 'Neutron',
  nova = 'Nova',
  qbert = 'Qbert',
  resmgr = 'ResMgr',
}

export interface DDUHealth {
  task_state: string
  desired_services: number
  ready_services: number
  service_details: { [key: string]: ServiceDetail }
}

export interface ServiceDetail {
  desired: number
  ready: number
}

export interface CustomerMetadata {
  [key: string]: any
}

interface IRequestOptions {
  clsName: string
  mthdName: string
  config?: any
  extractNestedData?: boolean
}
export interface IRawRequestGetParams {
  url: string
  version?: string
  endpoint?: string
  config?: AxiosRequestConfig
  options: IRequestOptions
}
export interface IRawRequestPostParams extends IRawRequestGetParams {
  data: any
}

export interface IBasicRequestGetParams {
  url: string
  version?: string
  params?: AxiosRequestConfig['params']
  endpoint?: string
  config?: AxiosRequestConfig
  options: IRequestOptions
  normalize?: boolean
}

export interface IBasicRequestPostParams {
  url: string
  version?: string
  body?: any
  endpoint?: string
  config?: AxiosRequestConfig
  options: IRequestOptions
}

export interface ApiErrorMetadata {
  isClusterError?: boolean
  k8sResource?: string
  k8sResourceName?: string
  k8sResourceAccessor?: string
  params?: any
}
export interface Method extends MethodMetadata {
  name: string
}

export interface MethodMetadata {
  url: string
  version?: string
  type: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE'
  params?: string[]
  error?: ApiErrorMetadata
  disable?: boolean
}

export interface IBasicRequestDeleteParams {
  url: string
  version?: string
  params?: AxiosRequestConfig['params']
  data?: AxiosRequestConfig['data']
  endpoint?: string
  config?: AxiosRequestConfig
  options: IRequestOptions
}

export interface ApiResponseErrorModel {
  isClusterError: boolean
  apiClassMetadata: ApiClassMetadata
  apiErrorMetadata: ApiErrorMetadata
  response: Response
  stack: string
  err: string | { message: string }
}

interface ApiClassMetadata {
  apiClass: string
  requestMethod: string
}

interface Response {
  config: Config
  data: ResponseData
  status: number
  statusText: string
}

interface Config {
  url: string
  method: string
  data: any
  headers: Record<string, string>
}

interface ResponseData {
  status: string
  message: string
  code: number
  data: string
}
