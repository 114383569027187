/* eslint-disable max-len */
import React from 'react'

export const argoCDLogo = <ArgoCDLogo />

export function ArgoCDLogo({ width = '24', height = '35' }) {
  return (
    <svg fill="none" width={width} height={height} viewBox="0 0 150 194">
      <linearGradient
        id="ArgoCDLogo"
        gradientUnits="userSpaceOnUse"
        x1="75"
        x2="106.522"
        y1="17.4121"
        y2="125.731"
      >
        <stop offset="0" stopColor="#5e6294" />
        <stop offset="1" stopColor="#878bb2" />
      </linearGradient>
      <circle cx="75" cy="72.3164" fill="url(#a)" opacity=".2" r="54.9043" />
      <path
        clipRule="evenodd"
        d="m75 140.857c37.854 0 68.541-30.687 68.541-68.5409 0-37.854-30.687-68.54071-68.541-68.54071s-68.54068 30.68671-68.54068 68.54071c0 37.8539 30.68668 68.5409 68.54068 68.5409zm0-13.637c30.323 0 54.904-24.581 54.904-54.904 0-30.3228-24.581-54.9043-54.904-54.9043-30.3228 0-54.9043 24.5815-54.9043 54.9043 0 30.323 24.5815 54.904 54.9043 54.904z"
        fill="#5e6294"
        fillRule="evenodd"
        opacity=".14"
      />
      <path
        d="m43.6259 101.083c-7.5104-3.2527-12.7647-10.7311-12.7647-19.4368 0-8.14 4.5937-15.2071 11.3302-18.7504-.4671-18.6096 14.4964-34.0008 33.1675-34.0008 18.671 0 33.6341 15.3912 33.1671 34.0008 6.737 3.5433 11.33 10.6104 11.33 18.7504 0 8.7057-5.254 16.1841-12.764 19.4368l-1.226 32.572c.351 8.432 2.893 9.131 12.457 11.761.923.253 1.911.525 2.969.824-7.878 1.8-12.827 2.048-15.941 1.108l-.566 15.04c-1.264 14.215-1.137 14.248 8.458 16.745 1.305.34 2.785.725 4.46 1.197-20.6605 4.723-20.9294-4.635-21.1727-13.097-.0486-1.691-.0962-3.346-.305-4.845h-.7701c-.0462.569-.0913 1.122-.135 1.659l-.0011.014c-.9638 11.835-1.2946 15.897.5635 17.688.9709.936 2.5396 1.252 4.9274 1.734 2.498.503 5.891 1.186 10.434 2.948-24.362 3.392-24.3434-4.346-24.3117-17.521.005-2.05.0102-4.231-.076-6.522h-.7313c-1.1292 25.397.1829 25.719 8.8219 27.838 2.347.576 5.2351 1.284 8.7611 2.664-24.8261 2.759-25.8195-4.296-27.3498-15.164-.2711-1.926-.5591-3.971-.9993-6.102-.4403 2.131-.7283 4.176-.9993 6.102-1.5303 10.868-2.5238 17.923-27.35 15.164 3.5265-1.38 6.4142-2.088 8.7612-2.664 8.639-2.119 9.9511-2.441 8.8219-27.838h-.7313c-.0862 2.291-.0809 4.472-.076 6.522.0317 13.175.0503 20.913-24.3117 17.521 4.5431-1.762 7.9364-2.445 10.4335-2.948 2.3883-.482 3.957-.798 4.9279-1.734 1.8581-1.791 1.5273-5.853.5635-17.688-.0441-.541-.0895-1.099-.1361-1.673h-.77c-.2089 1.499-.2565 3.154-.3051 4.845-.2432 8.462-.5122 17.82-21.1724 13.097 1.6751-.472 3.1548-.857 4.4595-1.197 9.5953-2.497 9.7221-2.53 8.4585-16.745l-.566-15.04c-3.1148.94-8.0632.692-15.9412-1.108 1.058-.299 2.0462-.571 2.969-.824 9.5639-2.63 12.1059-3.329 12.4569-11.761z"
        fill="#ef7b4d"
      />
      <circle cx="50.9569" cy="82.0053" fill="#fefefe" r="15.7895" />
      <circle cx="50.5981" cy="79.4931" fill="#000" r="5.38278" />
      <circle cx="97.6077" cy="82.0053" fill="#fefefe" r="15.7895" />
      <circle cx="97.2488" cy="79.4931" fill="#000" r="5.38278" />
      <path
        d="m63.1579 111.431s5.7416 5.741 12.2009 5.741c6.4594 0 12.201-5.741 12.201-5.741v7.177c0 6.738-5.4626 12.201-12.201 12.201s-12.2009-5.463-12.2009-12.201z"
        fill="#fafafa"
      />
      <path
        d="m63.1579 117.172s5.7416 5.742 12.2009 5.742c6.4594 0 12.201-5.742 12.201-5.742v1.436c0 6.738-5.4626 12.201-12.201 12.201s-12.2009-5.463-12.2009-12.201z"
        fill="#202124"
      />
      <path
        d="m29.4129 106.647c-7.1369-9.4818-11.3875-21.2634-11.4401-34.0437-.0849-20.5964 10.7539-38.6889 27.072-48.7879"
        opacity=".16"
        stroke="#5e6294"
        strokeLinecap="round"
        strokeWidth="4.30622"
      />
      <g fill="#5e6294">
        <circle cx="52.3923" cy="18.8474" opacity=".45" r="2.87081" />
        <rect height="33.0144" rx="2.87081" width="6.45933" y="56.168" />
        <rect height="33.0144" rx="2.87081" width="6.45933" x="143.541" y="56.168" />
        <path
          clipRule="evenodd"
          d="m75 144.445c39.836 0 72.129-32.293 72.129-72.1288 0-39.8359-32.293-72.129188-72.129-72.129188-39.8358 0-72.12918 32.293288-72.12918 72.129188 0 39.8358 32.29338 72.1288 72.12918 72.1288zm0-3.588c37.854 0 68.541-30.687 68.541-68.5407 0-37.854-30.687-68.5407-68.541-68.5407s-68.54069 30.6867-68.54069 68.5407c0 37.8537 30.68669 68.5407 68.54069 68.5407z"
          fillOpacity=".2"
          fillRule="evenodd"
        />
      </g>
      <path
        d="m67.7847 45.1095c-.7213 3.3756-3.6526 5.6106-6.5473 4.992-2.8947-.6185-4.7506-3.3207-4.0293-6.6963s5.1328-6.9616 8.0275-6.343c2.8946.6185 3.2704 4.6717 2.5491 8.0473z"
        fill="#f77f4f"
      />
    </svg>
  )
}
