import ApiClient from './ApiClient'
import { emptyArr, isNilOrEmpty } from 'utils/fp'
import { ApiResponseErrorModel, MethodMetadata } from './model'
import { isEmpty, propOr } from 'ramda'
import config from 'app-config'
import { sessionActions } from 'core/session/sessionReducers'
import { cacheActions } from 'core/caching/cacheReducers'
import store from 'app/store'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { pathJoin } from 'utils/misc'
import {
  sunpikeBaseUrlTpl,
  qbertBaseUrlTpl,
  keystoneAdminEndpoint,
  keystoneEndpoint,
} from 'api-client/constants'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { createUrlWithQueryString } from 'core/plugins/route'

const allClustersSelector = makeParamsAllClustersSelector()
const defaultTestTenant = 'Development Team Tenant'

export const makeClient = () => new ApiClient({ keystoneEndpoint })
export const makeAdminClient = () => new ApiClient({ keystoneEndpoint: keystoneAdminEndpoint })

export const getUserPass = () => {
  const username = config.username || config.simulator.username
  const password = config.password || config.simulator.password
  if (!username || !password) {
    throw new Error('username and/or password not specified in config.js')
  }
  return { username, password }
}

export const makeUnscopedClient = async () => {
  const { username, password } = getUserPass()
  const client = makeClient()
  await client.keystone.authenticate(username, password)
  return client
}

export const makeScopedClient = async (tenantName = defaultTestTenant) => {
  const client = await makeUnscopedClient()
  const projects = await client.keystone.getProjects()
  const project = projects.find((x) => x.name === tenantName) || projects[0]
  await client.keystone.changeProjectScopeWithToken(project.id, false)
  return client
}

export const setActiveRegion = async (activeRegion) => {
  const { keystone } = ApiClient.getInstance()
  store.dispatch(sessionActions.updateSession({ activeRegion, loadingRegion: true }))
  let features = {}
  try {
    await keystone.resetCookie()
    await ApiClient.refreshApiEndpoints()
    await store.dispatch(cacheActions.clearCache())
    features = await keystone.getFeatures()
  } finally {
    // Store entirety of features json in context for global usage
    store.dispatch(sessionActions.updateSession({ features, loadingRegion: false }))
  }
}

export const makeRegionedClient = async (tenantName = defaultTestTenant) => {
  const client = await makeScopedClient(tenantName)
  const regions = await client.keystone.getRegions()
  // currently set KVM-Neutron as default test environment
  const activeRegion = regions.find((x) => x.id === 'KVM-Neutron').id || regions[0].id
  setActiveRegion(activeRegion)
  await client.keystone.getServicesForActiveRegion()
  return client
}

export const waitUntil = async ({ condition, delay, maxRetries }) => {
  let done = await condition()
  let retry = 0
  while (!done && retry++ < maxRetries) {
    await sleep(delay)
    done = await condition()
  }
  if (!done) {
    throw new Error('Task not done within time.')
  }
}

export const sleep = async (delay) => new Promise((resolve) => setTimeout(resolve, delay))

export const getHighestRole = (roleNames) => {
  if (roleNames.includes('admin')) {
    return 'admin'
  } else if (roleNames.includes('_member_')) {
    return '_member_'
  } else {
    return roleNames[0]
  }
}

interface ResponseWithData {
  data: any
}

export const normalizeResponse = <T>(
  response,
  fixNestedData = true,
): T extends ResponseWithData ? T['data'] : T => {
  const data = response && response.hasOwnProperty('data') ? response.data : response
  // Fix nested data.data issue
  if (!fixNestedData) return data || emptyArr
  return (data && data.hasOwnProperty('data') ? data.data : data) || emptyArr
}

function replacer(template, obj: Record<string, string | number>) {
  const keys = Object.keys(obj)
  return keys.reduce((acc, key) => acc.replace(`:${key}`, obj[key]), template)
}

export const getApiUrl = (
  url: string,
  clusterId: string,
  extraParams: Record<string, string | number> = {},
  queryParams: Record<string, string> = {},
) => {
  if (!isNilOrEmpty(clusterId)) {
    const state = store.getState()
    const allClusters: CombinedClusterSelector[] = allClustersSelector(state)
    const { clusterType, name: clusterName, namespace = 'default' } = allClusters.find(
      (cluster) => cluster.uuid === clusterId,
    )
    let parsedUrl = url
    if (clusterType === ClusterTypes.Capi) {
      parsedUrl = replacer(pathJoin(sunpikeBaseUrlTpl, parsedUrl), {
        clusterId,
        clusterName,
        namespace,
        ...extraParams,
      })
    } else {
      parsedUrl = replacer(pathJoin(qbertBaseUrlTpl, parsedUrl), { clusterId, ...extraParams })
    }

    return isEmpty(queryParams) ? parsedUrl : createUrlWithQueryString(parsedUrl, queryParams)
  }
  console.error(`clusterId not defined for api call to ${pathJoin(qbertBaseUrlTpl, url)}`)
  const path = pathJoin(qbertBaseUrlTpl, url)
  return isEmpty(path) ? path : createUrlWithQueryString(path, queryParams)
}

export const trackApiMethodMetadata = (metadata: MethodMetadata) => (
  target: any,
  methodName: string,
) => {
  target.constructor.apiMethodsMetadata.push({
    ...metadata,
    name: methodName,
    params: propOr([], 'params', metadata),
    disable: !!metadata?.disable,
  })
}

export const generateApiErrorPayload = (error: ApiResponseErrorModel, title = '') =>
  error
    ? {
        title,
        message: typeof error.err === 'string' ? error.err : error?.err?.message,
        raw: error,
      }
    : undefined

export const generateSunpikeErrorPayload = (error: ApiResponseErrorModel, title = '') =>
  error
    ? {
        title: ` Error with ${error?.apiErrorMetadata?.k8sResource}`,
        message: typeof error.err === 'string' ? error.err : error?.response?.data,
        raw: error,
      }
    : undefined
