import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

import ProductInfoPage from 'core/containers/ProductInfoPage'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import CardButton from 'core/elements/button/CardButton'

import { ArgoCDLogo } from '../logo'
import { argoCDReleaseDocs, argoCDQuickstart, argoCDTerms } from '../links'
import ExternalLink from 'core/components/ExternalLink'
import { trackEvent } from 'utils/tracking'

export default function InformationalLandingPage() {
  const classes = useStyles()
  const handleOpenLinkClick = (url, eventName) =>
    useCallback(() => {
      trackEvent(eventName)
      window.open(url)
    }, [])
  return (
    <ProductInfoPage
      className={classes.argoPage}
      title="Welcome to ArgoCD"
      footerTitle="more useful links"
      icon={<ArgoCDLogo width="299" height="299" />}
      actions={[
        <CardButton
          onClick={handleOpenLinkClick(argoCDQuickstart, 'Clicked ArgoCD Learn More')}
          key="argo-cd-learn-more"
          icon="plus"
          title="Want to Learn More?"
          message="Read our docs & follow our tutorials to get started."
        />,
        <CardButton
          onClick={handleOpenLinkClick(argoCDReleaseDocs, 'Clicked ArgoCD Help')}
          key="argo-cd-need-help"
          icon="check-double"
          title="Need Help?"
          message="Read the official docs for ArgoCD."
        />,
      ]}
    >
      <div className={classes.paragraphSection}>
        <Text variant="subtitle2">What is ArgoCD?</Text>
        <Text variant="body1">
          ArgoCD is a Kubernetes Native GitOps continuous delivery system that is automatically
          connected to your Platform9 managed clusters. To get started, all you need to do is
          provide an application and ArgoCD will deploy it and automatically keep it synchronized to
          your Git repo.
        </Text>
      </div>

      <div className={classes.paragraphSection}>
        <Text variant="subtitle2">
          Click Launch ArgoCD and Login using your Platform9 account to try it out!
        </Text>
      </div>

      <Button
        rightIcon="arrow-up-right-from-square"
        onClick={handleOpenLinkClick(
          `${window.location.origin}/argocd/login`,
          'Clicked Launch ArgoCD',
        )}
      >
        Launch ArgoCD
      </Button>
    </ProductInfoPage>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  argoPage: {
    maxWidth: 1160,
    gridTemplateAreas: `
      "product-info-figure product-info-title"
      "product-info-figure product-info-body"
      "product-info-figure product-info-footer"
    `,

    '& > .product-info-body': {
      gap: 32,
    },

    '@media (max-width:1440px)': {
      maxWidth: 895,
      '& > .product-info-figure svg': {
        width: 225,
        height: 225,
      },
    },
    '@media (max-width:992px)': {
      maxWidth: 'max-content',
      gridTemplateAreas: `
        "product-info-figure"
        "product-info-title"
        "product-info-body"
        "product-info-footer"
      `,
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(4, max-content)',

      '& > .product-info-figure svg': {
        width: 105,
        height: 105,
      },
    },
  },
  paragraphSection: {
    display: 'grid',
    gap: 16,
  },
}))
